var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"px-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"disabled":"","label":"Job Id"},model:{value:(_vm.integration.job_id),callback:function ($$v) {_vm.$set(_vm.integration, "job_id", $$v)},expression:"integration.job_id"}})],1)]}}])},[_c('span',[_vm._v(" Id do job gerado pelo starlord ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-select',{attrs:{"rules":[_vm.notEmptyRule],"item-text":"text","item-value":"value","label":"ERP","items":_vm.erpList},model:{value:(_vm.integration.config.driver),callback:function ($$v) {_vm.$set(_vm.integration.config, "driver", $$v)},expression:"integration.config.driver"}})],1)]}}])},[_c('span',[_vm._v(" Erp que será utilizado ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-autocomplete',{attrs:{"multiple":"","item-text":"formated_name","outlined":"","item-value":"value","label":"Lojas","items":_vm.listUnit},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0 && _vm.selectedUnits.length === 1)?_c('span',[_vm._v(_vm._s(item.formated_name))]):_vm._e(),(index === 0 && _vm.selectedUnits.length > 1)?_c('span',[_vm._v(_vm._s(_vm.selectedUnits.length)+" selecionados")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedUnits),callback:function ($$v) {_vm.selectedUnits=$$v},expression:"selectedUnits"}})],1)]}}])},[_c('span',[_vm._v(" Lista das lojas vinculadas a conta ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"label":"Url"},model:{value:(_vm.integration.config.mercashop.url),callback:function ($$v) {_vm.$set(_vm.integration.config.mercashop, "url", $$v)},expression:"integration.config.mercashop.url"}})],1)],1)],1)]}}])},[_c('span',[_vm._v(" URL para comunicar com a API. ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"label":"Secret Key"},model:{value:(_vm.integration.config.mercashop.secret_key),callback:function ($$v) {_vm.$set(_vm.integration.config.mercashop, "secret_key", $$v)},expression:"integration.config.mercashop.secret_key"}})],1)],1)],1)]}}])},[_c('span',[_vm._v(" Chave de secreta de acesso para comunicar com a API, obtida no arquivo .env que está no servidor. ")])])],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"disabled":"","label":"Cron"},model:{value:(_vm.integration.cron_schedule),callback:function ($$v) {_vm.$set(_vm.integration, "cron_schedule", $$v)},expression:"integration.cron_schedule"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog_cron = true}}},[_vm._v("mdi-plus")])],1)],1)],1)]}}])},[_c('span',[_vm._v(" Campo para configuração da cron ")])])],1),(_vm.edit)?_c('v-col',{staticClass:"px-4",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-col',[_c('mf-toggle',{attrs:{"label":"Ativo","color":"#334A58"},model:{value:(_vm.integration.active),callback:function ($$v) {_vm.$set(_vm.integration, "active", $$v)},expression:"integration.active"}})],1)],1)],1)]}}],null,false,2398638387)},[_c('span',[_vm._v(" Indica se a integração está ativa ")])])],1):_vm._e(),_c('v-col',{staticClass:"json-input",class:_vm.disableJsonEditor ? 'readonly-json-editor' : '',attrs:{"cols":"12"}},[_c('v-jsoneditor',{attrs:{"plus":false,"height":"250px","options":_vm.editorOptions,"width":"300px"},on:{"error":_vm.handleError},model:{value:(_vm.integration.config.erp),callback:function ($$v) {_vm.$set(_vm.integration.config, "erp", $$v)},expression:"integration.config.erp"}})],1)],1),_c('dialog-cron',{on:{"confirmAction":_vm.SetCron},model:{value:(_vm.dialog_cron),callback:function ($$v) {_vm.dialog_cron=$$v},expression:"dialog_cron"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }